<template>
  <v-row no-gutters>
    <v-col lg="12" md="12" cols="12" class="pa-2">
      <v-card outlined>
        <v-card-title class="pb-0">
          <v-row no-gutters>
            <v-col cols="5">Customer List</v-col>
            <v-col cols="3" class="pt-3 px-2">
              <v-select
                v-model="selectedCustomerType"
                return-value="customerType"
                label="Customer Type"
                :items="customerType"
                placeholder=" "
                dense
              ></v-select>
            </v-col>
            <v-col cols="3" class="pt-0">
              <v-text-field
                v-model="selectedCustomer"
                label="Search Customer"
                placeholder="Customer Name"
                hide-details
              ></v-text-field>
              <!-- <v-autocomplete
                :items="customerListBySearch"
                v-model="selectedCustomer"
                item-text="customerName"
                label="Search Customer"
                placeholder=" "
                return-object
                clearable
                dense
              ></v-autocomplete>-->
            </v-col>
            <v-col cols="1" class="pt-2 text-right">
              <v-btn class="ma-1" depressed fab small @click="searchCustomer" color="primary">
                <v-icon>search</v-icon>
              </v-btn>
              <v-btn class="ma-1" depressed fab small @click="contactDialog=true" color="primary">
                <v-icon>add</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="px-2">
          <v-expansion-panels v-model="panel" focusable>
            <v-expansion-panel disabled>
              <v-expansion-panel-header class="pr-3 textCss">
                <v-row no-gutters>
                  <div class="WidthCss text-right">No.</div>
                  <div class="WidthCss text-right">Id.</div>
                  <div class="WidthCssName pl-2">Name</div>
                  <div class="WidthCssAddress">Address</div>
                  <div class="WidthCssPhone">Phone</div>
                  <div class="WidthCssDef">NRC</div>
                  <div class="WidthCssDef">Passport</div>
                  <div class="WidthCssDef">Date Of Birth</div>
                  <div class="WidthCssDef">Remark</div>
                  <div class="WidthCssDef">Type</div>
                  <div></div>
                </v-row>
              </v-expansion-panel-header>
            </v-expansion-panel>
            <v-expansion-panel
              v-for="(customer,i) in customerList"
              @click="getRelativeByCustomer(customer,i)"
              :key="i"
            >
              <v-expansion-panel-header class="pr-3 textCss">
                <v-row no-gutters>
                  <div class="WidthCss text-right">{{i+1}}</div>
                  <div class="WidthCss text-right">{{customer.customerId}}</div>
                  <div class="WidthCssName pl-2">{{customer.customerName}}</div>
                  <div class="WidthCssAddress px-1">{{customer.address}}</div>
                  <div class="WidthCssPhone">{{customer.phone}}</div>
                  <div class="WidthCssDef">{{customer.nrc}}</div>
                  <div class="WidthCssDef">{{customer.passport}}</div>
                  <div class="WidthCssDef">{{customer.dob}}</div>
                  <div class="WidthCssDef">{{customer.remark}}</div>
                  <div class="WidthCssDef">{{customer.type}}</div>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" class="text-right pa-0">
                    <v-btn icon depressed color="primary" @click="selecteCustomerToAdd(customer)">
                      <v-icon>add_circle</v-icon>
                    </v-btn>
                    <v-btn icon depressed color="primary" @click="editCustomer(customer)">
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn icon depressed color="error" @click="deleteCustomer(customer)">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <v-card outlined class="black--text">
                      <v-data-table
                        :headers="customerHeader"
                        :items="forCustomer"
                        :items-per-page="-1"
                        hide-default-footer
                        class="elevation-0"
                        dense
                      >
                        <template v-slot:item.action="{ item }">
                          <v-btn
                            icon
                            depressed
                            color="primary"
                            @click="editSelectedCustomer(item,customer)"
                          >
                            <v-icon>edit</v-icon>
                          </v-btn>
                          <v-btn icon depressed color="error" @click="deleteSelectedCustomer(item)">
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-col>
    <div>
      <v-dialog v-model="contactDialog" persistent max-width="500">
        <v-card>
          <v-card-title primary-title>
            Customer
            <v-chip
              v-if="toCustomer.customerName != ''"
              small
              class="mx-2"
              color="primary"
            >{{toCustomer.customerName}}</v-chip>
          </v-card-title>
          <v-card-text class="py-1">
            <v-form ref="customerForm" v-model="validCustomer" v-on:submit.prevent lazy-validation>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-combobox
                    v-if="customer.status=='ADD' || customer.status == 'ADDSUB'"
                    v-model="customer.customerName"
                    :items="customerListBySearch"
                    item-text="customerName"
                    :rules="customerRule"
                    label="Customer Name"
                    name="customer"
                    required
                  ></v-combobox>
                  <v-text-field
                    v-model="customer.customerName"
                    placeholder="Customer Name"
                    :rules="customerRule"
                    label="Customer Name"
                    name="customer"
                    required
                    v-else
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pr-2">
                  <v-text-field
                    v-model="customer.phone"
                    placeholder="Customer Phone"
                    label="Customer Phone"
                    name="customerPhone"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="customer.nrc"
                    placeholder="Customer NRC"
                    label="Customer NRC"
                    name="customerNRC"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pr-2">
                  <v-text-field
                    v-model="customer.passport"
                    placeholder="Passport"
                    label="Passport"
                    name="customerPassport"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="customer.dob"
                    placeholder="Date Of Birth"
                    label="Date Of Birth"
                    name="customerDob"
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  :cols="(toCustomer.customerName == '')?6:12"
                  :class="(toCustomer.customerName == '')?'pr-2':'pr-0'"
                >
                  <v-text-field
                    v-model="customer.remark"
                    placeholder="Customer Remark"
                    label="Customer Remark"
                    name="customerRemark"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" v-if="toCustomer.customerName == ''">
                  <v-select v-model="customer.type" :items="customerType" label="Pessenger Type"></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="customer.address"
                    placeholder="Customer Address"
                    label="Customer Address"
                    name="customerAddress"
                    required
                    hide-details
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pt-1">
            <v-spacer></v-spacer>
            <v-btn
              v-if="customer.status=='ADD' || customer.status == 'ADDSUB'"
              :disabled="!validCustomer"
              @click="addCustomer"
              color="success"
              class="ma-1"
              depressed
            >
              <v-icon>add</v-icon>
              <span class="ml-2">Add</span>
            </v-btn>
            <v-btn
              v-if="customer.status=='UPDATE' || customer.status == 'UPDATESUB'"
              :disabled="!validCustomer"
              @click="updateCustomer"
              color="primary"
              class="ma-1"
              depressed
            >
              <v-icon>update</v-icon>
              <span class="ml-2">Update</span>
            </v-btn>
            <v-btn @click="cancelCustomer" color="error" class="ma-1" depressed outlined>
              <v-icon>close</v-icon>
              <span class="ml-2">Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-row>
</template>
<script>
import customerService from "../service/CustomerService";
export default {
  data() {
    return {
      contactDialog: false,
      customerType: ["ALL", "AGENT", "CUSTOMER"],
      selectedCustomerType: "ALL",
      selectedPanel: 0,
      panel: [],
      selectedCustomer: "",
      toCustomer: {
        customerName: "",
      },
      forCustomer: [],
      validCustomer: false,
      customer: {
        customerName: null,
        address: null,
        phone: null,
        nrc: null,
        passport: null,
        dob: null,
        remark: null,
        type: "CUSTOMER",
        status: "ADD",
      },
      customerRule: [(v) => !!v || "Customer Name is required"],
      customerHeader: [
        { text: "Customer Name", value: "customerName" },
        { text: "Address", value: "address" },
        { text: "Phone", value: "phone" },
        { text: "NRC", value: "nrc" },
        { text: "Passpost", value: "passport" },
        { text: "Date Of Birth", value: "dob" },
        { text: "Remark", value: "remark" },
        { text: "Action", value: "action" },
      ],
      customerList: [],
      customerListBySearch: [],
    };
  },
  mounted: function () {
    this.getCustomer();
  },
  watch: {
    selectedCustomerType: function (v) {
      if (v == "ALL") {
        this.getCustomer();
      } else {
        customerService.getCustomer().then((response) => {
          var filtered = response.filter((customer) => {
            return customer.type == v;
          });
          this.customerList.splice(0, this.customerList.length);
          this.customerList = filtered;
          this.customerListBySearch.splice(0, this.customerListBySearch.length);
          this.customerListBySearch = filtered;
        });
      }
    },
    // selectedCustomer: function (value) {
    //   if (value != undefined) {
    //     customerService.getCustomer().then((response) => {
    //       var allCustomer = [];
    //       allCustomer.push(response);
    //       var selected = response.find(
    //         ({ customerName }) => customerName === value.customerName
    //       );
    //       this.customerList.splice(0, this.customerList.length);
    //       this.customerList.push(selected);
    //     });
    //   } else {
    //     this.getCustomer();
    //   }
    // },
  },
  methods: {
    searchCustomer: function () {
      customerService
        .getCustomerBy(this.selectedCustomer, "customerName")
        .then((response) => {
          this.customerList.splice(0, this.customerList.length);
          this.customerList.push(...response);
        });
    },
    selecteCustomerToAdd: function (customer) {
      this.toCustomer = customer;
      this.customer = {
        customerName: null,
        address: null,
        phone: null,
        nrc: null,
        passport: null,
        dob: null,
        remark: null,
        type: "CUSTOMER",
        status: "ADDSUB",
      };
      this.contactDialog = true;
    },
    getRelativeByCustomer: function (customer, i) {
      customerService
        .getRelativeByCustomer(customer.customerId)
        .then((response) => {
          this.forCustomer.splice(0, this.forCustomer.length);
          this.forCustomer.push(...response);
          this.selectedPanel = i;
        });
    },
    addSelectedCustomer: function (customer) {
      customer.customerId = this.toCustomer.customerId;
      customerService.addSelectedCustomer(customer).then(() => {
        this.getRelativeByCustomer(this.toCustomer, this.selectedPanel);
        this.contactDialog = false;
        this.customer = {
          customerName: null,
          address: null,
          phone: null,
          nrc: null,
          passport: null,
          dob: null,
          remark: null,
          type: "CUSTOMER",
          status: "ADD",
        };
        this.$refs.customerForm.resetValidation();
        this.$swal(
          "Successful",
          "Added New Customer for Successful!",
          "success"
        );
      });
    },
    editSelectedCustomer: function (subCustomer, customer) {
      this.toCustomer = customer;
      this.customer = Object.assign({}, subCustomer);
      this.customer.status = "UPDATESUB";
      this.contactDialog = true;
    },
    updateSelectedCustomer: function (customer) {
      customerService.updateSelectedCustomer(customer).then(() => {
        this.$swal("Update Successful", "Customer is Updated!", "success");
        this.getRelativeByCustomer(this.toCustomer, this.selectedPanel);
        // this.panel.splice(0,this.panel.length);
        // this.panel.push(this.selectedPanel);
        this.$refs.customerForm.reset();
        this.customer = {
          customerName: null,
          address: null,
          phone: null,
          nrc: null,
          passport: null,
          dob: null,
          remark: null,
          type: "CUSTOMER",
          status: "ADD",
        };
        this.contactDialog = false;
      });
    },
    deleteSelectedCustomer: function (customer) {
      customerService.deleteSelectedCustomer(customer).then(() => {
        this.$swal("Delete Successful", "Customer is Deleted!", "success");
        this.getRelativeByCustomer(this.toCustomer, this.selectedPanel);
        this.panel.splice(0, this.panel.length);
        this.panel.push(this.selectedPanel);
        this.$refs.customerForm.reset();
        this.customer.status = "ADD";
      });
    },
    // customer
    getCustomer: function () {
      customerService.getCustomer().then((response) => {
        this.customerList.splice(0, this.customerList.length);
        this.customerList.push(...response);
        this.customerListBySearch.splice(0, this.customerListBySearch.length);
        this.customerListBySearch.push(...response);
      });
    },
    addCustomer: function () {
      if (this.customer.status == "ADD") {
        if (this.$refs.customerForm.validate()) {
          customerService
            .addCustomer(this.customer)
            .then(() => {
              this.$swal(
                "Successful",
                "Added New Customer Successful!",
                "success"
              );
              this.getCustomer();
              this.customer = {
                customerName: null,
                address: null,
                phone: null,
                nrc: null,
                passport: null,
                dob: null,
                remark: null,
                type: "CUSTOMER",
                status: "ADD",
              };
              this.$refs.customerForm.resetValidation();
              this.contactDialog = false;
            })
            .catch(() => {
              this.$swal(
                "Failed",
                "Cann't save this customer already saved",
                "error"
              );
            });
        }
      } else {
        if (this.$refs.customerForm.validate()) {
          this.addSelectedCustomer(this.customer);
        }
      }
    },
    editCustomer: function (customer) {
      customer.status = "UPDATE";
      this.customer = Object.assign({}, customer);
      this.contactDialog = true;
    },
    updateCustomer: function () {
      if (this.customer.status != "UPDATESUB") {
        if (this.$refs.customerForm.validate()) {
          customerService
            .updateCustomer(this.customer)
            .then(() => {
              this.$swal(
                "Updated Successful",
                "Customer is Updated!",
                "success"
              );
              this.getCustomer();
              this.customer = {
                customerName: null,
                address: null,
                phone: null,
                nrc: null,
                passport: null,
                dob: null,
                remark: null,
                type: "CUSTOMER",
                status: "ADD",
              };
              this.$refs.customerForm.resetValidation();
              this.contactDialog = false;
            })
            .catch((err) => {
              this.$swal("Failed", err.response.data.message, "error");
            });
        }
      } else {
        if (this.$refs.customerForm.validate()) {
          this.updateSelectedCustomer(this.customer);
        }
      }
    },
    deleteCustomer: function (customer) {
      this.$swal({
        title: "Are you sure?",
        text:
          "Once deleted, you will not be able to recover this data and its relative data!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((result) => {
        if (result) {
          customerService
            .deleteCustomer(customer)
            .then(() => {
              this.$swal(
                "Delete Successful",
                "Customer is Deleted!",
                "success"
              );
              this.getCustomer();
              this.panel = [];
              this.$refs.customerForm.reset();
              this.customer.status = "ADD";
            })
            .catch((err) => {
              this.$swal(
                "Failed",
                "This data is used in somewhere Or " +
                  err.response.data.message,
                "error"
              );
            });
        }
      });
    },
    cancelCustomer: function () {
      this.toCustomer = {
        customerName: "",
      };
      this.customer = {
        customerName: null,
        address: null,
        phone: null,
        nrc: null,
        passport: null,
        dob: null,
        remark: null,
        type: "CUSTOMER",
        status: "ADD",
      };
      this.contactDialog = false;
    },
  },
};
</script>
<style scoped>
.WidthCssDef {
  width: 120px !important;
}
.WidthCssPhone {
  width: 140px !important;
}
.WidthCssAddress {
  width: 350px !important;
}
.WidthCssName {
  width: 135px !important;
}
.WidthCss {
  width: 30px !important;
}
.textCss {
  font-size: 0.7rem !important;
}
.theme--light.v-expansion-panels .v-expansion-panel--disabled {
  color: black !important;
}
.v-expansion-panel-header {
  padding: 0px 8px 0px 8px !important;
  min-height: 30px;
}
</style>